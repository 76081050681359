import React, {} from "react";

import styles from './Contact.module.css';
import environment from '../../../environments/environment';

const Contact = ({ contact }) => {
    const type = contact?.type;
    let prefix = '';

    switch (type) {
        case 'phone':
            prefix = 'tel:';
            break;
        case 'mail':
            prefix = 'mailto:';
            break;
        case 'skype':
            prefix = 'skype:';
            break;
        default:
            break;
    }

    return (
        <div className={ styles.contact }>
          {contact && contact.icon &&
              <img src={`${environment.apiUrl}${contact.icon.url}`} className={ styles.icon } alt={ contact?.label }/>
          }
            <div className={ styles.divider }> .... </div>
          {contact &&
            <a href={`${environment.apiUrl}${prefix + contact.link}`} className={styles.link}>{contact?.value}</a>
          }
        </div>
    )
};

export default Contact;
