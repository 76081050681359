import React from "react";
import classNames from 'classnames';

import styles from './Socials.module.css';

const title = 'WE ARE IN SOCIALS';

const Socials = ({socials}) => {
    const getSocialStyle = (item) => {
        switch (item?.link) {
            case "https://www.linkedin.com/company/love-vector/":
                return styles.linkedin;
            case "https://clutch.co/profile/vector-incorporated#highlights":
                return styles.clutch;
            default:
                return styles.linkedin
        }
    }

    return (
        <div className={ styles.contacts }>
            <div>
                <div className={classNames('accent-text', styles.title)}>{
                    title
                }</div>

                <div className={styles.divider}>••••••••••••••••••••••••••••••••••••••</div>

                <div className={styles.socialItems}>
                    {
                        socials?.socials?.map((item) => {
                            return <a href={item?.link}
                                      key={item?.id}
                                      className={classNames(styles[item?.type] || getSocialStyle(item), styles.icon)} />
                        })
                    }
                </div>

            </div>

        </div>
    )
};

export default Socials;